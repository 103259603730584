body {
  &.modal-open {
    overflow: hidden;
  }
  @media only screen and (max-width: 767.9px) {
    &.options-open {
      overflow: hidden;
    }
  }
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
  max-width: 100vw;

  &.show {
    display: block;
  }

  &-backdrop {
    background-color: transparent;
    display: none;
    opacity: 0;
    transition: all 400ms ease-in-out;

    &.show {
      display: block;
    }
  }

  &-dialog {
    margin-left: auto;
    margin-right: auto;
    &.product-modal {
      max-width: 600px;
      .modal-header {
        padding: 0;
      }
      .modal-body {
        padding: 40px;
      }
    }

    &.metaproduct-modal {
      max-width: 900px;
      .modal-header {
        padding: 0;
      }
      .modal-body {
        padding: 50px 32px;
      }
    }

    &.ecotax-modal {
      color: var(--color-dark);
      z-index: var(--bs-backdrop-zindex);
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
    }
    @media only screen and (max-width: 991.9px) {
      &.product-modal {
        .modal-body {
          padding: 20px;
        }
      }
    }
    @media only screen and (min-width: 576px) {
      &.ecotax-modal {
        margin: 0;
        position: absolute;
        right: 0;
        .modal-content {
          padding: 100px 60px;
        }
        .modal-body {
          max-width: 290px;
        }
      }
    }
    @media only screen and (max-width: 575.9px) {
      &.ecotax-modal {
        margin-top: 100px;
        max-width: 320px;
        .modal-content {
          padding: 40px;
        }
      }
    }
  }

  &-content {
    border-radius: 0;
  }

  &-header {
    border: none;
    padding: 15px;
    justify-content: center;

    .btn-close {
      position: absolute;
      right: 15px;
      top: 15px;
      z-index: 200;
      background-color: var(--color-primary-color);
      height: 40px;
      width: 40px;
      color: var(--color-primary-white);
      border-radius: 50%;
      opacity: 1;
      padding: 0;
      background-image: none;
      &::before {
        content: '\f00d';
        font-family: 'Font Awesome 6 Pro';
        font-weight: 300;
      }
    }
  }

  &-title {
    text-align: center;
    margin-top: 30px;
    font-size: 16px;
    font-weight: bold;
  }

  &-product__price {
    .product-price {
      &__prices {
        flex-direction: column-reverse;
        align-items: flex-end;
      }

      &__total {
        font-weight: 600;
      }

      &__crossed {
        margin-right: 0;
        font-size: 14px;
      }
    }
  }

  &-metaproduct__price {
    .product-price {
      &__total {
        font-weight: 400;
        font-size: 36px;
      }

      &__crossed {
        font-size: 20px;
      }
    }
  }
}

.search-modal.modal {
  background-color: rgba(0, 0, 0, 0.85);
  display: block;
  transition: all 0.4s ease-in-out;

  visibility: hidden;
  opacity: 0;

  &.visible {
    opacity: 1;
    visibility: visible;
  }

  .modal-header {
    border-bottom: none;
  }

  .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translateY(-50px);
    &.show {
      transform: none;
    }
    pointer-events: all;
    max-width: none;
    position: absolute;
    width: 100%;
    top: 15vh;
  }

  .modal-content {
    background-color: transparent;
    border: none;
  }

  .modal-body {
    padding: 0;
  }
}

.contentful-video {
  z-index: 1051;
  transition: 0.2s ease-in-out;
  .modal-content {
    background-color: transparent;
    border: none;
    width: 800px;

    // Positioning the modal in the center of the screen
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    position: fixed;

    @media only screen and (max-width: 991px) {
      width: 80%;
    }

    .modal-header {
      button {
        background-color: transparent;
        font-size: xx-large;
        top: 40px;
      }
    }

    .modal-body {
      padding: 0;
      video {
        object-fit: cover;
        aspect-ratio: 4 / 3;
      }
    }
  }
}

.offcanvas-end {
  transform: translateX(100%);
  transition: all 400ms ease-in-out;

  &.show {
    transform: translateX(0%);
  }
}

#newsletter-popup {
  .modal {
    &-dialog {
      max-width: 900px;
      transform: translate(0, 0);
    }
    &-header {
      padding: 0;
    }
    &-body {
      padding: 0;
    }
  }
  @media only screen and (min-width: 768px) {
    .modal-body {
      max-height: calc(100vh - 60px);
    }
  }
  @media only screen and (max-width: 991.9px) {
    .modal-dialog {
      max-width: 90vw;
    }
  }
  @media only screen and (max-width: 767.9px) {
    .btn-close {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: grey;
      position: absolute;
      z-index: 1;
      padding: 0;
      border: none;
    }
  }
}

#sharedArticleModal {
  .modal-content {
    & .modal-header {
      & .modal-title {
        font-size: 1.5em;
      }

      & .btn-close {
        margin: 5px 5px 0 0;
      }
    }

    & .modal-body .modal-container {
      padding: 15px;

      button {
        margin-top: 15px;
      }
    }
  }
}
