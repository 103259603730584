.popover {
  border-radius: 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  z-index: 1000;
  font-size: 12px;

  &.bs-popover {
    &-top {
      border-bottom: 6px solid var(--color-primary-color);
      .popover-arrow {
        bottom: calc(-0.5rem - 5px);
        &::after {
          border-top-color: var(--color-primary-color);
        }
      }
    }
    &-bottom {
      border-top: 6px solid var(--color-primary-color);
      .popover-arrow {
        top: calc(-0.5rem - 5px);
        &::after {
          border-bottom-color: var(--color-primary-color);
        }
      }
    }
  }

  a {
    text-decoration: underline;
  }

  &-body {
    padding-top: 32px;
    .close {
      position: absolute;
      top: 7px;
      right: 7px;
      background-color: transparent;
      border: none;
    }
  }

  .popover-arrow {
    left: 15px !important;
    transform: none !important;
  }
}

.advantages-banner__slide {
  .popover-arrow {
    left: 35px !important;
  }
}
