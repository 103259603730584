// Slide animations
@keyframes slide_down {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slide_right {
  0% {
    visibility: visible;
    transform: translateX(0);
  }
  99% {
    visibility: visible;
    transform: translateX(100%);
  }
  100% {
    visibility: hidden;
    transform: translateX(100%);
  }
}
@keyframes slide_left {
  0% {
    transform: translateX(100%);
    visibility: hidden;
  }
  1% {
    visibility: visible;
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
    visibility: visible;
  }
}

// Expand animations
@keyframes grow_down {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 100vh;
  }
}

@keyframes grow_up {
  0% {
    max-height: 100vh;
  }
  100% {
    max-height: 0;
  }
}

@keyframes grid_down {
  0% {
    visibility: hidden;
    grid-template-rows: 0fr;
  }
  1% {
    visibility: visible;
    grid-template-rows: 0fr;
  }
  100% {
    visibility: visible;
    grid-template-rows: 1fr;
  }
}

@keyframes grid_up {
  0% {
    visibility: visible;
    grid-template-rows: 1fr;
  }
  99% {
    grid-template-rows: 0fr;
    visibility: visible;
  }
  100% {
    grid-template-rows: 0fr;
    visibility: hidden;
  }
}
