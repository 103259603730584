.dropup {
  .dropdown {
    &-toggle {
      .dropdown-label {
        font-weight: 700;
      }
      .dropdown-selected {
        color: var(--color-primary-color);
      }
      i {
        transition: all 400ms ease-in-out;
      }
      &::after {
        content: '';
        display: none;
      }
    }
    &-menu {
      width: 100%;
      bottom: 100%;
      margin-bottom: -1px;
    }
  }
  &.show {
    .dropdown-toggle {
      i {
        transform: rotate(180deg);
      }
    }
  }
}
.dropdown {
  &-toggle.button {
    background-color: var(--color-primary-white);
    border: 1px solid var(--Border-Color-Light);
    display: flex;
    padding: 10px var(--Spacing-L, 16px);
    align-items: center;
    gap: 12px;
    align-self: stretch;
    color: var(--color-primary-color);
    font-size: 16px;
    line-height: 22px;
    justify-content: space-between;
    min-width: 10rem;
  }
  &-menu {
    border-radius: 0;
    background: var(--color-gray-light);
  }
  &-item {
    font-size: 14px;
    line-height: 16px;
    padding: 10px 16px;
    cursor: pointer;
    white-space: normal;
    &.selected {
      color: var(--Text-Color-Primary, #202020);
      font-weight: 700;
    }
    a {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}
