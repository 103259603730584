.color-metaproduct {
  .color-name {
    padding-left: 5px;
    color: $gray;
  }

  .color-selector {
    padding: 15px 0px;
    margin: 0px;
    list-style-type: none;

    li {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border: 1px solid $gray-300;
      border-radius: 100px;
      margin: 0px 15px 15px 0px;

      a {
        display: inline-block;
        width: 30px;
        height: 30px;
        border-radius: 100px;
        cursor: default;
      }
    }

    li:not(.selected):hover {
      transform: scale(1.2);
      border-width: 2px;
      border: 1.5px solid $blue;
      transition-property: all;
      transition-timing-function: ease-in-out;
      transition-duration: 0.15s;
    }

    .selected {
      border: 1.5px solid $blue;
      background-color: $white;
    }
  }
}
