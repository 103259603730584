@use 'sass:math';

@import '@scalefast/components/src/scss/base/variables';

// Meta Products support. All components file should be added, but I don't know what's the plan for oral B styles
@import '@scalefast/components/src/scss/components/product/_color-metaproduct';
@import '@scalefast/components/src/scss/components/product/_options-metaproduct';

//@import '../../node_modules/@scalefast/components/src/scss/components/index';
@import './variables'; // Store custom overrides
@import './components/modals';
@import './components/buttons';
@import './components/popovers';
@import './components/animations.scss';
@import './components/dropdowns.scss';
@import '../assets/fonts/_fonts.scss';

// Glide
// @import '../../node_modules/@glidejs/glide/src/assets/sass/glide.core.scss';
// @import '../../node_modules/@glidejs/glide/src/assets/sass/glide.theme.scss';

// Splide
@import '../../node_modules/@splidejs/splide/dist/css/themes/splide-default.min.css';

// Store custom modules
// @import './components';

// Pages -> Now is called in each wrapper component to improve performance
// @import './pages';

:root {
  --subscribe-newsletter-display: none;
}

// ----------------------------------------------------------------------------------
// Font family
// ----------------------------------------------------------------------------------
// Note: HurmeGeometricSansFallback is an adjusted Arial font that we wanted to use for low CLS,
// but we found performance regressions with fallback fonts https://support.scalefast.ninja/issues/157611
body {
  font-family: 'Hurme Geometric Sans', 'Arial';
}
