.options-metaproduct {
  .options-selector {
    list-style-type: none;
    padding: 10px 0px 15px 0px;

    li {
      display: inline-block;
      @media (max-width: 800px) {
        margin: 0 20px 20px 0;
      }
    }

    a {
      cursor: pointer;
      display: inline-block;
      padding: 8px;
      color: $black;
      border: 1px solid $black;
      margin-top: 12px;
      margin-right: 12px;
    }

    li.selected a {
      color: $blue;
      border-color: $blue;
      background-color: unset;
    }
  }
}
