@font-face {
  font-family: 'Hurme Geometric Sans';
  src:
    url('HurmeGeometricSans1-Bold.woff2') format('woff2'),
    url('HurmeGeometricSans1-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hurme Geometric Sans';
  src:
    url('HurmeGeometricSans1-Light.woff2') format('woff2'),
    url('HurmeGeometricSans1-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hurme Geometric Sans';
  src:
    url('HurmeGeometricSans1-Regular.woff2') format('woff2'),
    url('HurmeGeometricSans1-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hurme Geometric Sans';
  src:
    url('HurmeGeometricSans1-SemiBold.woff2') format('woff2'),
    url('HurmeGeometricSans1-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

// This font is used as a fallback and can be customized if needed, adjusting 'size-adjust' and 'ascent-override'
// properties. More info here:
// https://www.aleksandrhovhannisyan.com/blog/perfect-font-fallbacks/
// We use 'url' to ensure the font is inlined in the css, because beasties (old critters) library used by angular
// for inlining the CSS  only considers that the font has an src if it find url and skips processing it.
// https://github.com/danielroe/beasties/blob/a988fb4a9f7b830f17482a5dacc6e085ee9e8302/packages/beasties/src/index.ts#L596
//
// Note: HurmeGeometricSansFallback is currently not used, but we found performance regressions with fallback
// fonts https://support.scalefast.ninja/issues/157611
// @font-face {
//   font-family: 'HurmeGeometricSansFallback';
//   size-adjust: 95%;
//   ascent-override: 105%;
//   src:
//     local('Arial'),
//     url('HurmeGeometricSans1-Light.woff2') format('woff2');
// }
