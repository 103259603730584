// ----------------------------------------------------------------------------------
// Root variables
// ----------------------------------------------------------------------------------
/// The primary colors used throughout the design system.
/// @group theme-colors
/// @type Color
:root {
  --color-primary-color: #002d72;
  --color-primary-color-dark: #00002e;
  --color-primary-color-light: #97caeb;
  --color-light: #454758;
  --color-gray-lighter: #fafafa;
  --color-gray-light: #f7f7f7;
  --color-gray-medium: #f2f2f1;
  --color-gray-dark: #d9d8d6;
  --color-gray-100: #f4f4f4;
  --color-gray-gray-90: #3d3d41;
  --color-primary-white: #ffffff;
  --color-primary-light: #97caeb;
  --color-black: #000000;
  --color-dark: #333333;
  --color-text: #0a2240;
  --color-red: #c40000;
  --color-success: #5ca553;
  --color-blue-hover: #1a53ae;
  --color-blue-hover-light: #87a4d2;
  --color-primary-color-disabled-acc: rgba(0, 45, 114, 0.75);
  --color-primary-color-disabled: rgba(0, 45, 114, 0.5);
  --color-primary-02: rgba(0, 45, 114, 0.2);
  --bs-modal-width: 1000px;
  --bs-backdrop-zindex: 1050;
  --Border-Color-Light: #e0e0e0;
}

// Override bootstrap color variables

// Body
//
// Settings for the `<body>` element.
$body-color: #0a2240;

// scss-docs-start theme-color-variables
$primary: #002d72 !default;
$gray-100: #f7f7f7;
$dark: #0a2240 !default;
// scss-docs-end theme-color-variables

// ----------------------------------------------------------------------------------
// Font family
// ----------------------------------------------------------------------------------
/// This variable represents the font family to be used for sans-serif typography
// Note: HurmeGeometricSansFallback is an adjusted Arial font that we wanted to use for low CLS,
// but we found performance regressions with fallback fonts https://support.scalefast.ninja/issues/157611
/// @group typography
$font-family-sans-serif: 'Hurme Geometric Sans', 'Arial';

/// This variable represents the font family to be used for monospace typography
/// @group typography
$font-family-monospace: 'Hurme Geometric Sans', 'Arial';

// ----------------------------------------------------------------------------------
// Grid containers
// ----------------------------------------------------------------------------------
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
);

$modal-md: 600px;
