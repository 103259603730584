// ----------------------------------------------------------------------------------
// Primary colors
// ----------------------------------------------------------------------------------
/// The primary blue color used throughout the design system.
/// @group primary-colors
/// @type Color
$blue: #4087fb;

/// The primary black color used throughout the design system.
/// @group primary-colors
/// @type Color
$black: #161616;

/// The primary white color used throughout the design system.
/// @group primary-colors
/// @type Color
$white: #ffffff;

/// The primary red color used throughout the design system.
/// @group primary-colors
/// @type Color
$red: #fa4d56;

/// The primary orange color used throughout the design system.
/// @group primary-colors
/// @type Color
$orange: #e76b29;

/// The primary yellow color used throughout the design system.
/// @group primary-colors
/// @type Color
$yellow: #f4c63a;

/// The primary green color used throughout the design system.
/// @group primary-colors
/// @type Color
$green: #24a148;

/// The primary gray color used throughout the design system.
/// @group primary-colors
/// @type Color
$gray: #8e8e8e;

// ----------------------------------------------------------------------------------
// Gray shades
// ----------------------------------------------------------------------------------
/// The lightest shade of gray used throughout the design system.
/// @group gray-shades
/// @type Color
$gray-100: #f4f4f4;

/// A light shade of gray used throughout the design system.
/// @group gray-shades
/// @type Color
$gray-200: #e0e0e0;

/// A medium-light shade of gray used throughout the design system.
/// @group gray-shades
/// @type Color
$gray-300: #c6c6c6;

/// A medium shade of gray used throughout the design system.
/// @group gray-shades
/// @type Color
$gray-400: #aaaaaa;

/// The primary shade of gray used throughout the design system.
/// @group gray-shades
/// @type Color
$gray-500: $gray;

/// A medium-dark shade of gray used throughout the design system.
/// @group gray-shades
/// @type Color
$gray-600: #6e6f6e;

/// A dark shade of gray used throughout the design system.
/// @group gray-shades
/// @type Color
$gray-700: #515151;

/// A very dark shade of gray used throughout the design system.
/// @group gray-shades
/// @type Color
$gray-800: #363636;

/// The darkest shade of gray used throughout the design system.
/// @group gray-shades
/// @type Color
$gray-900: #202020;

/// The darkest possible shade of gray used throughout the design system.
/// @group gray-shades
/// @type Color
$gray-1000: #0d0d0d;

/// A map of all the gray shades used throughout the design system.
/// @group gray-shades
/// @type Map
$grays: (
  '1000': $gray-1000,
);

// ----------------------------------------------------------------------------------
// Blue shades
// ----------------------------------------------------------------------------------
/// The lightest shade of blue, used for backgrounds and borders.
/// @group blue-shades
/// @type Color
$blue-100: #e3f2ff;

/// A slightly darker shade of blue used for accent backgrounds and borders.
/// @group blue-shades
/// @type Color
$blue-200: #cfe1fd;

/// A darker shade of blue used for primary buttons and other prominent design elements.
/// @group blue-shades
/// @type Color
$blue-300: #a2c7fc;

/// A deeper shade of blue used for hover states and active states of interactive elements.
/// @group blue-shades
/// @type Color
$blue-400: #70a8fb;

/// The primary blue color used throughout the design system.
/// @group blue-shades
/// @type Color
$blue-500: $blue;

/// A deep shade of blue used for the hover and active states of primary buttons and other prominent design elements.
/// @group blue-shades
/// @type Color
$blue-600: #265ff8;

/// A darker shade of blue used for the hover and active states of secondary buttons and other less prominent design elements.
/// @group blue-shades
/// @type Color
$blue-700: #133fcb;

/// A very dark shade of blue used for the backgrounds of some less prominent design elements.
/// @group blue-shades
/// @type Color
$blue-800: #333ead;

/// The darkest shade of blue used in the design system.
/// @group blue-shades
/// @type Color
$blue-900: #02166a;

/// An extra dark shade of blue used for text on light backgrounds.
/// @group blue-shades
/// @type Color
$blue-1000: #00083d;

/// A map of extra blue shades.
/// @group blue-shades
/// @type Map
$blues: (
  'blue-1000': $blue-1000,
);

// ----------------------------------------------------------------------------------
// Red shades
// ----------------------------------------------------------------------------------
/// The lightest shade of red in the design system.
/// @group red-shades
/// @type Color
$red-200: #ffd7d9;

/// A lighter shade of red in the design system.
/// @group red-shades
/// @type Color
$red-300: #ffb3b8;

/// A moderate shade of red in the design system.
/// @group red-shades
/// @type Color
$red-400: #ff8389;

/// The primary red color used throughout the design system.
/// @group red-shades
/// @type Color
$red-500: $red;

/// A darker shade of red in the design system.
/// @group red-shades
/// @type Color
$red-600: #da1e28;

/// A dark shade of red in the design system.
/// @group red-shades
/// @type Color
$red-700: #a2191f;

/// A darker and stronger shade of red in the design system.
/// @group red-shades
/// @type Color
$red-800: #750e13;

/// The darkest and strongest shade of red in the design system.
/// @group red-shades
/// @type Color
$red-900: #520408;

/// The darkest shade of red in the design system.
/// @group red-shades
/// @type Color
$red-1000: #2d0709;

/// A map containing all shades of red in the design system.
/// @group red-shades
/// @type Map
$reds: (
  'red-1000': $red-1000,
);

// ----------------------------------------------------------------------------------
// Green shades
// ----------------------------------------------------------------------------------
/// The lightest shade of green used in the design system.
/// @group green-shades
/// @type Color
$green-100: #defbe6;

/// A light shade of green used in the design system.
/// @group green-shades
/// @type Color
$green-200: #a7f0ba;

/// A medium shade of green used in the design system.
/// @group green-shades
/// @type Color
$green-300: #6fdc8c;

/// A darker shade of green used in the design system.
/// @group green-shades
/// @type Color
$green-400: #42be65;

/// The primary green color used throughout the design system.
/// @group green-shades
/// @type Color
$green-500: $green;

/// A darker shade of the primary green color used in the design system.
/// @group green-shades
/// @type Color
$green-600: #198038;

/// A dark shade of green used in the design system.
/// @group green-shades
/// @type Color
$green-700: #0e6027;

/// A very dark shade of green used in the design system.
/// @group green-shades
/// @type Color
$green-800: #044317;

/// The darkest shade of green used in the design system.
/// @group green-shades
/// @type Color
$green-900: #022d0d;

/// The darkest shade of green used in the design system (almost black).
/// @group green-shades
/// @type Color
$green-1000: #071908;

/// A map of all the green shades.
/// @group green-shades
/// @type Map
$greens: (
  'green-1000': $green-1000,
);

// ----------------------------------------------------------------------------------
// Font family
// ----------------------------------------------------------------------------------
/// This variable represents the font family to be used for sans-serif typography
/// @group typography
$font-family-sans-serif: Manrope;

/// This variable represents the font family to be used for monospace typography
/// @group typography
$font-family-monospace: Manrope;

/// This variable represents the font weight to be used for light typography
/// @group typography
$font-weight-light: 400;

/// This variable represents the font weight to be used for normal typography
/// @group typography
$font-weight-normal: 500;

/// This variable represents the font size to be used for a size of 50
/// @group typography
$font-size-50: 0.625rem;

/// This variable represents the font size to be used for a size of 75
/// @group typography
$font-size-75: 0.75rem;

/// This variable represents the font size to be used for a size of 100
/// @group typography
$font-size-100: 0.875rem;

/// This variable represents the font size to be used for a size of 200
/// @group typography
$font-size-200: 1rem;

/// This variable represents the font size to be used for a size of 300
/// @group typography
$font-size-300: 1.25rem;

// ----------------------------------------------------------------------------------
// Grid Overrides
// ----------------------------------------------------------------------------------
/// Defines the padding on the X axis of the container.
$container-padding-x: 20px;

// ----------------------------------------------------------------------------------
// Others
// ----------------------------------------------------------------------------------
/// Defines the text decoration for links.
$link-decoration: none;

// ----------------------------------------------------------------------------------
// Buttons
// ----------------------------------------------------------------------------------
/// Defines the border radius of buttons.
$btn-border-radius: 0;

// ----------------------------------------------------------------------------------
// Forms
// ----------------------------------------------------------------------------------
/// This variable defines the URL of the SVG image to be used as an invalid feedback icon in forms.
/// The SVG image should have a size of 24x24 and a red fill color (#FA4D56).
$form-feedback-icon-invalid: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_181_8221)"><path d="M12 4.6275L3.48755 21H20.5126L12 4.6275ZM11.1525 9H12.8401V15.75H11.1525V9ZM11.9925 19.5C11.37 19.5 10.8675 18.9975 10.8675 18.375C10.8675 17.7525 11.37 17.25 11.9925 17.25C12.615 17.25 13.1175 17.7525 13.1175 18.375C13.1175 18.9975 12.615 19.5 11.9925 19.5Z" fill="#FA4D56"/><path d="M21.7501 22.5H2.25008C1.98758 22.5 1.74758 22.365 1.61258 22.14C1.47758 21.915 1.47008 21.6375 1.59008 21.405L11.3326 2.655C11.6101 2.115 12.3826 2.115 12.6601 2.655L22.4101 21.405C22.5301 21.6375 22.5226 21.915 22.3876 22.14C22.2526 22.365 22.0051 22.5 21.7501 22.5V22.5ZM3.48758 21H20.5126L12.0001 4.6275L3.48758 21V21Z" fill="#FA4D56"/></g><defs><clipPath id="clip0_181_8221"><rect width="24" height="24" fill="white"/></clipPath></defs></svg>');

// ----------------------------------------------------------------------------------
// Tabs
// ----------------------------------------------------------------------------------
/// This variable sets the width of the border of the navigation tabs in the UI. The value should be in pixels.
$nav-tabs-border-width: 2px;

// ----------------------------------------------------------------------------------
// Errors
// ----------------------------------------------------------------------------------
/// This variable represents the color to be used for error messages
$error: $red;
